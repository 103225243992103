<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="월 사용실적 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && cofirmCheck"
                  :url="confirmUrl"
                  :isSubmit="isConfirm"
                  :param="monthlyPerformance"
                  mappingType="PUT"
                  label="검토완료"
                  icon="check"
                  @beforeAction="submitData('C')"
                  @btnCallback="submitCallback" />
                <c-btn 
                  v-show="editable && cofirmCheck"
                  :isSubmit="isReturn"
                  :url="confirmUrl"
                  :param="monthlyPerformance"
                  mappingType="PUT"
                  label="반려" 
                  icon="front_hand"
                  color="red"
                  @beforeAction="submitData('R')"
                  @btnCallback="submitCallback" />
                <c-btn
                  v-show="editable && permitCheck && popupParam.settlementMonth && popupParam.smfMonthlyPerformanceId"
                  :url="permitUrl"
                  :isSubmit="isSubmit"
                  :param="monthlyPerformance"
                  mappingType="PUT"
                  label="일괄제출"
                  icon="check"
                  @beforeAction="submitData('P')"
                  @btnCallback="submitCallback" />
                <c-btn
                  v-show="editable && permitCheck && popupParam.settlementMonth"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="monthlyPerformance"
                  :mappingType="mappingType"
                  label="일괄저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn v-show="editable && permitCheck && popupParam.settlementMonth && popupParam.smfMonthlyPerformanceId && deleteCheck" label="삭제" icon="remove" @btnClicked="removePlan" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-plant 
                  :disabled="true"
                  :editable="editable"
                  name="plantCd" 
                  v-model="monthlyPerformance.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="공사기간"
                  name="projectPeriod"
                  v-model="monthlyPerformance.projectPeriod"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  label="안전보건관리비 예산(원)"
                  name="projectSafetyCost"
                  v-model="monthlyPerformance.projectSafetyCost">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  label="누적사용액(원)"
                  name="stackAmount"
                  v-model="monthlyPerformance.stackAmount">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="해당 안전보건관리비 사용가능한 업체"
                  name="vendorNames"
                  v-model="monthlyPerformance.vendorNames">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-datepicker
                  :disabled="isOld"
                  :editable="editable"
                  type="month"
                  label="정산월"
                  name="settlementMonth"
                  v-model="monthlyPerformance.settlementMonth"
                  @datachange="monthChange"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="작성일/작성자"
                  name="register"
                  v-model="register">
                </c-text>
              </div>
              <div v-if="cofirmCheck || monthlyPerformance.confirmRemark" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :disabled="!cofirmCheck"
                  :editable="editable"
                  label="검토 상세내용"
                  name="confirmRemark"
                  v-model="monthlyPerformance.confirmRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-tab
            class="q-mt-sm preStartup-tab-no-padding"
            type="tabcard"
            align="left"
            :height="tabHeight"
            :editable="editable"
            :tabItems.sync="monthlyPerformance.vendors"
            :inlineLabel="true"
            :emptyShow="true"
            addTabLabel="업체 추가"
            :addTab="editable && permitCheck && Boolean(popupParam.settlementMonth)"
            :removeTab="editable && permitCheck && deleteCheck"
            removeConditionCol="completeFlag"
            removeConditionVal="Y"
            v-model="tab"
            @addTab="addTab"
            @removeTab="removeTab"
            @tabClick="tabClick"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :key="tab.key"
                :vendor.sync="vendor"
                :permitCheck.sync="permitCheck"
                :popupParam.sync="popupParam"
                :plant.sync="plant"
                :tabHeight="tabHeight"
                @getDetail="getDetail"
              />
            </template>
            <template v-slot:emptyItems>
              <div>
                <div v-if="!monthlyPerformance.settlementMonth" class="text-h6">
                  <q-chip square>
                    <q-avatar color="red" text-color="white">!</q-avatar>
                    정산월을 입력바랍니다.
                  </q-chip>
                </div>
              </div>
            </template>
          </c-tab>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'monthly-performance-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        smfMonthlyPerformanceId: '',
        plantCd: '',
        settlementMonth: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: '',
      monthlyPerformance: {
        smfMonthlyPerformanceId: '',  // 월별 안전보건관리비 사용실적 번호
        plantCd: '',  // 공사현장 코드
        projectPeriod: '',
        companyCd: '',
        projectSafetyCost: 0,
        stackAmount: 0, // 누적액
        settlementMonth: '',  // 정산월
        smfMonthlyStepCd: '',  // 진행상태
        confirmRemark: '',  // 진행상태
        vendorCds: '',
        vendorNames: '',
        regUserId: '',  // 등록자 ID
        regUserName: '',
        regDtStr: '',  // 등록일
        chgUserId: '',  // 수정자 ID
        vendors: [],
        deleteVendors: [],
      },
      plant: {
        plantCd: null,
        plantName: null,
        projectPeriod: null,
        projectSafetyCost: 0,
        stackAmount: 0,
        projectAddress: '',
        projectCost: 0,
        statementItemUse: [],
      },
      vendor: {},
      mappingType: 'POST',
      detailUrl: '',
      stateListUrl: '',
      saveUrl: '',
      permitUrl: '',
      confirmUrl: '',
      deleteUrl: '',
      deleteVendorUrl: '',
      editable: true,
      isSave: false,
      isVendorSave: false,
      isSubmit: false,
      isConfirm: false,
      isReturn: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 260);
    },
    cofirmCheck() {
      return this.popupParam.smfMonthlyPerformanceId && this.monthlyPerformance.smfMonthlyStepCd === 'SM00000005'
    },
    permitCheck() {
      return !this.popupParam.smfMonthlyPerformanceId || this.monthlyPerformance.smfMonthlyStepCd === 'SM00000001' 
    },
    register() {
      return this.monthlyPerformance.regDtStr && this.monthlyPerformance.regUserName ? this.monthlyPerformance.regDtStr + ' / ' + this.monthlyPerformance.regUserName : ''
    },
    isOld() {
      return Boolean(this.popupParam.smfMonthlyPerformanceId)
    },
    deleteCheck() {
      return this.monthlyPerformance.regUserId === this.$store.getters.user.userId
            || this.$store.getters.user.innerFlag === 'Y'
    },
    vendorCd() {
      return this.$store.getters.user.deptCd
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.smf.monthlyPerformance.get.url
      this.stateListUrl = selectConfig.smf.statementItemUse.list.url
      this.saveUrl = transactionConfig.smf.monthlyPerformance.insert.url
      this.permitUrl = transactionConfig.smf.monthlyPerformance.update.url
      this.confirmUrl = transactionConfig.smf.monthlyPerformance.update.url
      this.deleteUrl = transactionConfig.smf.monthlyPerformance.delete.url
      this.deleteVendorUrl = transactionConfig.smf.monthlyPerformanceVendor.delete.url
      this.getDetail();
      this.getState();
    },
    getDetail(isConfirm) {
      this.$http.url = this.detailUrl
      this.$http.type = 'GET';
      this.$http.param = {
        smfMonthlyPerformanceId: this.popupParam.smfMonthlyPerformanceId,
        plantCd: this.popupParam.plantCd,
        settlementMonth: this.popupParam.settlementMonth,
      }
      this.$http.request((_result) => {
        if (isConfirm && _result.data && _result.data.smfMonthlyPerformanceId) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '해당 월에 실적이 있습니다.\n\r월의 상세를 보시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.setMonthly(_result.data)
            },
            // 취소 callback 함수
            cancelCallback: () => {
              this.monthlyPerformance.settlementMonth = null;
            },
          });
        } else {
          this.setMonthly(_result.data)
        }
      },);
    },
    setMonthly(data) {
      this.$_.extend(this.monthlyPerformance, data)
      if (!this.monthlyPerformance.plantCd) {
        this.monthlyPerformance.plantCd = this.popupParam.plantCd
      }
      if (this.monthlyPerformance.smfMonthlyPerformanceId && !this.popupParam.smfMonthlyPerformanceId) {
        this.popupParam.smfMonthlyPerformanceId = this.monthlyPerformance.smfMonthlyPerformanceId
      }
      if (!this.monthlyPerformance.settlementMonth) {
        this.monthlyPerformance.settlementMonth = this.popupParam.settlementMonth
      }
      if (this.monthlyPerformance.vendors && this.monthlyPerformance.vendors.length > 0) {
        this.$_.forEach(this.monthlyPerformance.vendors, vendor => {
          this.$set(vendor, 'name', vendor.vendorCd);
          this.$set(vendor, 'label', vendor.vendorName);
          this.$set(vendor, 'icon', 'apartment');
          this.$set(vendor, 'key', uid());
          this.$set(vendor, 'component', () => import(`${'./monthlyPerformanceInfo.vue'}`));
        })
        let idx = this.$_.findIndex(this.monthlyPerformance.vendors, { vendorCd: this.tab });
        if (!this.tab ||
          idx === -1) {
            this.tab = this.monthlyPerformance.vendors[0].vendorCd
            this.vendor = this.monthlyPerformance.vendors[0];
        } else {
          this.vendor = this.monthlyPerformance.vendors[idx];
        }
      } else {
        this.tab = 'empty'
      }
      if (this.$store.getters.user.innerFlag === 'N' && this.monthlyPerformance.settlementMonth) {
        let isSetting = false;
        if (!this.monthlyPerformance.vendors || this.monthlyPerformance.vendors.length === 0) {
          isSetting = true
        }
        if (this.$_.findIndex(this.monthlyPerformance.vendors, { name: this.$store.getters.user.deptCd }) === -1) {
          this.monthlyPerformance.vendors.push({
            smfMonthlyPerformanceVendorId: uid(),  // 월별 안전보건관리비 업체 번호
            smfMonthlyPerformanceId: this.popupParam.smfMonthlyPerformanceId,  // 월별 안전보건관리비 사용실적 번호
            vendorCd: this.$store.getters.user.deptCd,  // 업체코드
            vendorName: this.$store.getters.user.deptName,  // 업체명
            completeFlag: 'N',  // 완료여부
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C',
            itemResult: [],
            deleteItemResult: [],
            // tab item
            name: this.$store.getters.user.deptCd,
            label: this.$store.getters.user.deptName,
            icon: 'apartment',
            key: uid(),
            component: () => import(`${'./monthlyPerformanceInfo.vue'}`),
          })
          if (isSetting) {
            this.tab = this.monthlyPerformance.vendors[0].vendorCd
            this.vendor = this.monthlyPerformance.vendors[0];
          }
          setTimeout(() => {
            this.saveVendor();
          }, 300);
        }
      }
    },
    getState() {
      if (this.popupParam.settlementMonth) {
        this.$http.url = this.stateListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.popupParam.plantCd,
          month: this.popupParam.settlementMonth,
          companyCd: this.$store.getters.user.companyCd,
        }
        this.$http.request((_result) => {
          this.$_.extend(this.plant, _result.data)
        },);
      }
    },
    monthChange(month) {
      this.isVendorSave = !this.isVendorSave
      this.$set(this.popupParam, 'settlementMonth', month)
      if (month && this.isVendorSave) {
        this.getDetail(true);
        this.getState();

      }
    },
    addTab() {
      let vendors = [];
      if(this.$store.getters.user.innerFlag === 'N') {
        vendors.push(this.$store.getters.user.deptCd)
      } else if (this.monthlyPerformance.vendorCds && this.monthlyPerformance.vendorCds.length > 0 ) {
        vendors = this.monthlyPerformance.vendorCds.split(",");
      }
      if (!this.popupParam.settlementMonth) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '정산월을 먼저 입력바랍니다.', // 정산월을 먼저 입력바랍니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '업체 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.popupParam.plantCd,
        vendorCds: vendors
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let isSetting = false;
        if (!this.monthlyPerformance.vendors || this.monthlyPerformance.vendors.length === 0) {
          isSetting = true
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.monthlyPerformance.vendors, { name: item.vendorCd }) === -1) {
            let pushData = {
              smfMonthlyPerformanceVendorId: uid(),  // 월별 안전보건관리비 업체 번호
              smfMonthlyPerformanceId: this.popupParam.smfMonthlyPerformanceId,  // 월별 안전보건관리비 사용실적 번호
              vendorCd: item.vendorCd,  // 업체코드
              vendorName: item.vendorName,  // 업체명
              completeFlag: 'N',  // 완료여부
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
              itemResult: [],
              deleteItemResult: [],
              // tab item
              name: item.vendorCd,
              label: item.vendorName,
              icon: 'apartment',
              key: uid(),
              component: () => import(`${'./monthlyPerformanceInfo.vue'}`),
            }
            this.monthlyPerformance.vendors.push(pushData)
          }
        })
        if (isSetting) {
          this.tab = this.monthlyPerformance.vendors[0].vendorCd
          this.vendor = this.monthlyPerformance.vendors[0];
        }
        setTimeout(() => {
          this.saveVendor();
        }, 300);
      }
    },
    saveVendor() {
      if (this.popupParam.smfMonthlyPerformanceId) {
        this.saveUrl = transactionConfig.smf.monthlyPerformance.update.url
        this.mappingType = 'PUT';
        this.monthlyPerformance.chgUserId = this.$store.getters.user.userId;
      } else {
        this.saveUrl = transactionConfig.smf.monthlyPerformance.insert.url
        this.mappingType = 'POST';
        this.monthlyPerformance.smfMonthlyStepCd = 'SM00000001'
        this.monthlyPerformance.companyCd = this.$store.getters.user.companyCd;
        this.monthlyPerformance.regUserId = this.$store.getters.user.userId;
      }
      this.$http.url = this.saveUrl;
      this.$http.type = this.mappingType;
      this.$http.param = this.monthlyPerformance;
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.popupParam.smfMonthlyPerformanceId = _result.data
        this.getDetail();
        this.getState();
      },);
    },
    removeTab(tab) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '제외하시겠습니까? ',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteVendorUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: tab,
          };
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.monthlyPerformance.vendors = this.$_.reject(this.monthlyPerformance.vendors, tab)
            if (this.monthlyPerformance.vendors && this.monthlyPerformance.vendors.length > 0) {
              this.tab = this.monthlyPerformance.vendors[0].vendorCd
              this.vendor = this.monthlyPerformance.vendors[0];
            }
          },);
          
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tabClick(tab) {
      this.tab = tab.name
      this.vendor = this.$_.find(this.monthlyPerformance.vendors, { vendorCd: tab.vendorCd });
    },
    saveData() {
      if (this.monthlyPerformance.vendors && this.monthlyPerformance.vendors.length > 0) {
        let dataFilter = this.$_.filter(this.monthlyPerformance.vendors, vendor => {
          console.log(vendor)
          let _dataFilter = this.$_.filter(vendor.itemResult, item => {
            return !item.smfItemCd || !item.settlementDate || !item.quantity || !item.unitPrice 
          })
          return (_dataFilter && _dataFilter.length > 0) || !vendor.itemResult || vendor.itemResult.length === 0
        })
        if (dataFilter && dataFilter.length > 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '실적이 제대로 입력되지 않았습니다.\n\r확인 후 진행바랍니다.', // 실적이 등록되지 않았습니다.\n\r업체를 추가하여 실적등록하십시오.
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      }
      if (this.popupParam.smfMonthlyPerformanceId) {
        this.saveUrl = transactionConfig.smf.monthlyPerformance.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.smf.monthlyPerformance.insert.url
        this.mappingType = 'POST';
        this.monthlyPerformance.smfMonthlyStepCd = 'SM00000001'
        this.monthlyPerformance.companyCd = this.$store.getters.user.companyCd;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.monthlyPerformance.regUserId = this.$store.getters.user.userId;
              this.monthlyPerformance.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.smfMonthlyPerformanceId = result.data
      this.getDetail();
      this.getState();
    },
    submitData(flag) {
      if (!this.monthlyPerformance.vendors || this.monthlyPerformance.vendors.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '실적이 등록되지 않았습니다.\n\r업체를 추가하여 실적등록하십시오.', // 실적이 등록되지 않았습니다.\n\r업체를 추가하여 실적등록하십시오.
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        let dataFilter = this.$_.filter(this.monthlyPerformance.vendors, vendor => {
          let _dataFilter = this.$_.filter(vendor.itemResult, item => {
            return !item.smfItemCd || !item.settlementDate || !item.quantity || !item.unitPrice 
          })
          return (_dataFilter && _dataFilter.length > 0) || !vendor.itemResult || vendor.itemResult.length === 0
        })
        if (dataFilter && dataFilter.length > 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '실적이 제대로 입력되지 않았습니다.\n\r확인 후 진행바랍니다.', // 실적이 등록되지 않았습니다.\n\r업체를 추가하여 실적등록하십시오.
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      }
      let message = flag === 'P' ? '일괄 제출하시겠습니까?' : flag === 'C' ? '검토완료하시겠습니까?' : '반려하시겠습니까?'
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.monthlyPerformance.chgUserId = this.$store.getters.user.userId;
              if (flag === 'P') {
                this.monthlyPerformance.smfMonthlyStepCd = 'SM00000005'
                this.isSubmit = !this.isSubmit;
              } else if (flag === 'C') {
                this.monthlyPerformance.smfMonthlyStepCd = 'SM00000010'
                this.isConfirm = !this.isConfirm;
              } else {
                this.monthlyPerformance.smfMonthlyStepCd = 'SM00000001'
                this.isReturn = !this.isReturn;
              }
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          this.monthlyPerformance.smfMonthlyStepCd = flag === 'P' ? 'SM00000001' : 'SM00000005'
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    submitCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.smfMonthlyPerformanceId = result.data
      this.getDetail();
      this.getState();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.monthlyPerformance.smfMonthlyPerformanceId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
